import { ExtraResultStatus } from '@careos/toxicology-types';

export const getResultsStatus = (chiralStatus: ExtraResultStatus) => {
  switch (chiralStatus) {
    case 'not-ordered':
    case 'abandoned':
      return '';
    case 'expected':
      return '1/2';
    case 'reported':
      return '2/2';
    default:
      throw new Error(`unhandled case for: ${chiralStatus}`);
  }
};
