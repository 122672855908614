<template>
  <OrderViewWrapper>
    <OrderViewCard
      data-test-id="requisition-created"
      :title="t('created.requisition_created')"
      class="relative w-full max-w-4xl pb-12"
    >
      <template v-if="store.selectedOrderType === 'CoC'">
        <div class="mt-5 text-left font-semibold">
          {{ t('created.requisition_password') }}
        </div>
        <p
          class="rounded bg-careos-purple-light text-xl font-semibold font-mono"
          data-test-id="signed-consent-password"
        >
          {{ store.donorPassword }}
        </p>
        <div
          class="my-5 flex w-0 min-w-full items-center rounded-md bg-careos-purple-light p-2 text-left"
        >
          <div class="ml-2 h-full">
            <PrimeIcon icon="USER_EDIT" size="XL" class="text-careos-purple" />
          </div>
          <div class="mx-4 my-2">
            {{ t('created.remind_donor_note_password') }}
          </div>
        </div>
      </template>

      <CareosSecondaryButton
        :label="
          showDetails ? t('created.hide_details') : t('created.show_details')
        "
        class="my-5 w-full"
        @click="showDetails = !showDetails"
      />

      <CareosButton
        :label="t('created.create_new')"
        class="mt-5 w-full"
        @click="returnToCreateRequisition"
      />

      <Transition name="created">
        <div v-if="showDetails" class="mt-8">
          <PdfEmbed
            v-if="store.signedRequisitionPdf"
            :pdf-content="store.signedRequisitionPdf"
          />
        </div>
      </Transition>
      <template #footer><div /></template>
    </OrderViewCard>
  </OrderViewWrapper>
</template>

<script setup lang="ts">
import CareosButton from '@/components/CareosButton.vue';
import CareosSecondaryButton from '@/components/CareosSecondaryButton.vue';
import { router } from '@/router';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave } from 'vue-router';
import OrderViewCard from '../../components/OrderViewCard.vue';
import OrderViewWrapper from '../../components/OrderViewWrapper.vue';
import { useOrderViewStore } from '../../store/order-view-store';
import { PrimeIcon } from 'careos-vue-components';
import PdfEmbed from '@/components/PdfEmbed.vue';

const store = useOrderViewStore();

const { t } = useI18n();
const showDetails = ref(false);

const returnToCreateRequisition = () => {
  router.push('/order/requisition-type');
};

onBeforeRouteLeave(() => {
  store.clearOrder();
});
</script>

<style scoped>
.created-enter-active,
.created-leave-active {
  transition: all 0.3s ease-in-out;
}

.created-enter-from,
.created-leave-to {
  transform: translateY(-20%);
  opacity: 0;
}
</style>
